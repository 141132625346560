import { type AsyncData } from "nuxt/app";
import { usePagination } from "../paginations/usePagination";
import { useFetchApi } from "~/composables/api/useFetchApi";

export const useTrainingPartnersApi = () => {
  async function create(partner_name: string): Promise<AsyncData<any, any>> {
    return await useFetchApi("trainings", "/training-partners/own-partner/create-or-get-partner", {
      method: "POST",
      body: { partner_name },
    });
  }

  async function fetchTrainingPartners(lazy: boolean, search?: string): Promise<AsyncData<any, any>> {
    return await useFetchApi("trainings", `/training-partners`, {
      method: "GET",
      ...(lazy && { query: { limit: 10, lazy_load: true, search } }),
    });
  }

  async function fetchAllTrainingPartnersWithFilters(search?: string): Promise<AsyncData<any, any>> {
    const pagination = usePagination();

    if (pagination.paginateOptions.value?.filter === "Organismes") pagination.paginateOptions.value.filter = null;

    const paginate_options = pagination.getFindOptions(pagination.paginateOptions.value);

    const paginate_query = new URLSearchParams(paginate_options).toString();

    return await useFetchApi("trainings", `/training-partners?${paginate_query}`, {
      method: "GET",
      query: {
        lazy_load: true,
      },
    });
  }

  async function findOneTrainingPartner(trainingPartnerId: string): Promise<AsyncData<any, any>> {
    return await useFetchApi("trainings", `/training-partners/${trainingPartnerId}`, {
      method: "GET",
    });
  }

  async function updateMainContact(partner_id: string, email: string): Promise<AsyncData<any, any>> {
    return await useFetchApi("trainings", `/training-partners/own-partner/main-contact`, {
      method: "PATCH",
      body: { partner_id, email },
    });
  }

  async function updateTrainingPartner(body: {
    training_partner_id: string;
    name: string;
    main_contact_email: string;
    main_contact_firstname: string;
    main_contact_lastname: string;
  }): Promise<AsyncData<any, any>> {
    return await useFetchApi("trainings", `/training-partners/register/partner_infos`, {
      method: "POST",
      body,
    });
  }

  return {
    create,
    fetchTrainingPartners,
    updateMainContact,
    findOneTrainingPartner,
    fetchAllTrainingPartnersWithFilters,
    updateTrainingPartner,
  };
};
