import { TrainingPartnersApi } from "@simbelapp/trainings-sdk";
import { defineStore } from "pinia";
import { useSDKApi } from "~/composables/api/useSDKApi";
import { MicroserviceEnum } from "~/utils/enums/common.enums";
import { useFeedback } from "~~/composables/feedback/useFeedback";
import { useTrainingPartnersApi } from "~~/composables/training-partners/useTrainingPartnersApi";
import type { ITrainingPartnersStore } from "~~/utils/interfaces/training-partners-interfaces";

export const useTrainingPartnersStore = defineStore("TrainingPartnersStore", {
  state: (): ITrainingPartnersStore => {
    return {
      training_partners: [],
      training_partner: null,
      show_edit_training_partner: false,
      edit_training_partner: {
        id: null,
        name: null,
        main_contact_email: null,
        main_contact_firstname: null,
        main_contact_lastname: null,
        logo: null,
      },
    };
  },
  getters: {},
  actions: {
    async create(body: { name: string }) {
      const trainingPartnersApi = useTrainingPartnersApi();
      const feedback = useFeedback();

      try {
        const { data } = await trainingPartnersApi.create(body.name);
        return data;
      } catch (error) {
        feedback.error(`erreur`, "small");
      }
    },

    async updateMainContact(body: { partner_id: string; email: string }) {
      const trainingPartnersApi = useTrainingPartnersApi();
      const { data } = await trainingPartnersApi.updateMainContact(body.partner_id, body.email);
      return data;
    },

    async fetchAllTrainingPartners(lazy = false, search?: string) {
      const trainingPartnersApi = useTrainingPartnersApi();

      const { data } = await trainingPartnersApi.fetchAllTrainingPartnersWithFilters(search);
      if (data.value) {
        this.training_partners = data.value;
      }
    },

    // Done to avoid an impact on fetchAllTrainingPartners
    async fetchTrainingPartnersLazyWithStore(search?: string) {
      const trainingPartnersApi = useTrainingPartnersApi();

      const { data } = await trainingPartnersApi.fetchTrainingPartners(true, search);
      if (data.value?.data) {
        this.training_partners = data.value.data;
      }
    },

    async findOneTrainingPartner(partner_id: string) {
      const trainingPartnersApi = useTrainingPartnersApi();
      const feedback = useFeedback();

      try {
        const { data } = await trainingPartnersApi.findOneTrainingPartner(partner_id);
        return data.value;
      } catch (error) {
        feedback.error(`erreur`, "small");
      }
    },
    init() {
      this.edit_training_partner.main_contact_email = null;
      this.edit_training_partner.name = null;
      this.edit_training_partner.main_contact_firstname = null;
      this.edit_training_partner.main_contact_lastname = null;
      this.edit_training_partner.logo = null;
    },
    async openEditTrainingPartner(partner_id: string) {
      this.show_edit_training_partner = true;

      const { logo, main_contact_email, name, main_contact_firstname, main_contact_lastname } =
        await this.findOneTrainingPartner(partner_id);

      this.edit_training_partner.name = name;
      this.edit_training_partner.main_contact_email = main_contact_email;
      this.edit_training_partner.main_contact_firstname = main_contact_firstname;
      this.edit_training_partner.main_contact_lastname = main_contact_lastname;
      this.edit_training_partner.logo = logo;
      this.edit_training_partner.id = partner_id;
    },
    closeModal() {
      this.init();

      this.show_edit_training_partner = false;
    },
    async convertTrainingPartnerLogo(logo: File) {
      const apiInstance = await useSDKApi(MicroserviceEnum.TRAININGS, TrainingPartnersApi);
      const response = await apiInstance.convertTrainingPartnersLogo({
        file: logo,
      });

      const convertedImg = await response;
      if (convertedImg) {
        this.edit_training_partner.logo = new File([convertedImg], logo.name, { type: "image/png" });
      }
    },
    async updateTrainingPartnerLogo(logo: File) {
      const apiInstance = await useSDKApi(MicroserviceEnum.TRAININGS, TrainingPartnersApi);
      if (this.edit_training_partner.id) {
        const response = await apiInstance.updateTrainingPartnerLogo({
          trainingPartnerId: this.edit_training_partner.id,
          file: logo,
        });
      }
    },
    async saveTrainingPartner() {
      const trainingPartnersApi = useTrainingPartnersApi();
      const feedback = useFeedback();

      try {
        if (
          this.edit_training_partner.id &&
          this.edit_training_partner.name &&
          this.edit_training_partner.main_contact_email &&
          this.edit_training_partner.main_contact_firstname &&
          this.edit_training_partner.main_contact_lastname
        ) {
          if (this.edit_training_partner.logo && this.edit_training_partner.logo instanceof File) {
            await this.convertTrainingPartnerLogo(this.edit_training_partner.logo as File);
            await this.updateTrainingPartnerLogo(this.edit_training_partner.logo as File);
          }

          const body = {
            training_partner_id: this.edit_training_partner.id,
            name: this.edit_training_partner.name,
            main_contact_email: this.edit_training_partner.main_contact_email,
            main_contact_firstname: this.edit_training_partner.main_contact_firstname,
            main_contact_lastname: this.edit_training_partner.main_contact_lastname,
            ...(!this.edit_training_partner.logo && { logo: this.edit_training_partner.logo }),
          };

          await trainingPartnersApi.updateTrainingPartner(body);
          this.fetchAllTrainingPartners(true);
          // this.closeModal();
          feedback.success(`L’organisme a bien été mis à jour`, "small");
        }
      } catch (error) {
        feedback.error(`erreur`, "small");
      }
    },
  },
});
